import React from 'react';

const Services = () => {
  const servicesList = [
    "Corporate Law",
    "International Dispute Resolution",
    "Investment Advising & Litigation",
    "Intellectual Property Law",
    "International Arbitration",
    "Immigration Law",
    "Administrative Law",
    "Testing Yet Again"
  ];

  return (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-serif mb-8 text-center text-gray-800">Our Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {servicesList.map((service, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2 text-gray-800">{service}</h3>
              <p className="text-gray-600">Expert legal counsel and representation.</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;