// const config = {
//     apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:5001',
//     baseUrl: process.env.REACT_APP_BASE_URL || 'http://localhost:3000'
//   };
  
//   export default config;

const config = {
  apiUrl: process.env.REACT_APP_API_URL || 'https://api.ghafoorlawco.com',
  baseUrl: process.env.REACT_APP_BASE_URL || 'https://ghafoorlawco.com'
};

export default config;